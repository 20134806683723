import '@simonwep/pickr/dist/themes/nano.min.css';
import Pickr from '@simonwep/pickr';

// docs https://github.com/Simonwep/pickr
export function initColorPicker(dotNetRef, initialValue) {
    const pickr = Pickr.create({
        el: '.color-picker',
        container: 'body',
        theme: 'nano',
        inline: false,
        disabled: false,
        lockOpacity: true,
        comparison: false,
        default: initialValue,
        defaultRepresentation: 'HEX',
        appClass: 'picker-wrap',
        components: {
            preview: true,
            opacity: false,
            hue: true,
            interaction: {
                hue: true,
                hex: false,
                rgba: false, // Display 'input/output format as rgba' button (red green blue and alpha)
                hsla: false, // Display 'input/output format as hsla' button (hue saturation lightness and alpha)
                hsva: false, // Display 'input/output format as hsva' button (hue saturation value and alpha)
                cmyk: false, // Display 'input/output format as cmyk' button (cyan mangenta yellow key )

                input: true, // Display input/output textbox which shows the selected color value.
                // the format of the input is determined by defaultRepresentation,
                // and can be changed by the user with the buttons set by hex, rgba, hsla, etc (above).
                cancel: false, // Display Cancel Button, resets the color to the previous state
                clear: false, // Display Clear Button; same as cancel, but keeps the window open
                save: false,  // Display Save Button,
            },
        },
    });

    pickr.on('changestop', (source, instance) => {
        var y = instance.getColor().toHEXA().toString();
        dotNetRef.invokeMethodAsync('ColorSetFromPicker',y)
    })

    pickr.on('change', (color, source, instance) => {
        var y = color.toHEXA().toString();
        dotNetRef.invokeMethodAsync('ColorSetFromPicker', y)
    })
}