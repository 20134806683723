export function saveAsFile(filename, bytesBase64) {
    var link = document.createElement('a');
    link.download = filename;
    link.href = "data:application/octet-stream;base64," + bytesBase64;
    document.body.appendChild(link); // Needed for Firefox
    link.click();
    document.body.removeChild(link);
}

export function getTimezoneOffset() {
    let filePromise = new Promise(resolve => {
        var date = new Date();
        resolve(date.getTimezoneOffset());
    });
    return filePromise;
}

export function getTimezoneOffsetForJan() {
    let filePromise = new Promise(resolve => {
        var currentDate = new Date();
        var date = new Date(currentDate.getFullYear(), 0, 1);
        resolve(date.getTimezoneOffset());
    });
    return filePromise;
}

export function getTimezoneOffsetForJuly() {
    let filePromise = new Promise(resolve => {
        var currentDate = new Date();
        var date = new Date(currentDate.getFullYear(), 6, 1);
        resolve(date.getTimezoneOffset());
    });
    return filePromise;
}

export function printPage() {
    window.print();
}
