import { initColorPicker } from './js/ColorPickerWrapper';
window.initColorPicker = initColorPicker;

import './js/Helpers';
import * as helpers from './js/Helpers';
Object.assign(window, helpers);

// skipping bundling ej2 and interop libraries - already minimized and ready to use
// require('./js/vendor/syncfusion-17.4.40/ej2.min');
// require('./js/vendor/syncfusion-17.4.40/ejs.interop.min');

// require('@fortawesome/fontawesome-pro/js/solid.min');
// require('@fortawesome/fontawesome-pro/js/regular.min');
